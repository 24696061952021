/*---------------------------
  Public Pages 
----------------------------*/

.public-page {
  background: linear-gradient(rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6)),
    url("./assets/images/black-african-professional-women-worker.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  height: auto;
}

/* Header */
.public-page header {
  background: #fff;
  box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
  padding: 0.65rem 0;
}

.public-page .header-wrap {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 460px) {
  .public-page .header-wrap {
    flex-direction: column;
  }
}

.public-page .navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.public-page .navbar li {
  position: relative;
}
.public-page .navbar > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 8px 0 8px 20px;
}
.public-page .navbar a,
.public-page .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  text-transform: uppercase;
  white-space: nowrap;
  transition: 0.3s;
  border-bottom: 2px solid #fff;
  padding: 5px 2px;
  text-decoration: none;
  font-weight: 600;
}
.public-page .navbar a i,
.public-page .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.public-page .navbar a:hover,
.public-page .navbar .active,
.public-page.navbar .active:focus,
.public-page .navbar li:hover > a {
  border-color: #fff;
}

/* Main */
.public-page main {
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.public-page .content {
  color: white;
}

/* Homepage */
.pretitle {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.6;
}

.title {
  color: #f8ac00;
  font-size: 42px;
  font-weight: 900;
  line-height: 1.2em;
  margin-bottom: 0.2em;
}

/* Sign-up */
.eligibility-list li {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}

.eligibility-list svg {
  margin-right: 10px;
}

/*---------------------------
  Admin Pages 
----------------------------*/
.admin-page {
  background: #eff1f2;
}

.bx--header {
  background: #ef4800;
  border-bottom: 1px solid #fff;
}

.bx--header__name {
  width: 16rem;
  background: #fff;
}

.bx--header__name img {
  max-width: 160px;
  max-height: 45px;
  margin: auto;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 1rem;
}

.user-details svg {
  margin-bottom: 5px;
}

.bx--side-nav__submenu-title {
  color: #000;
}

.dashboard {
  margin-top: 2rem;
}

.dashboard .card-title,
.inner-cards .card-title {
  font-size: 1.2rem;
}

/*---------------------------
  Common styles
----------------------------*/
.content p {
  margin-bottom: 1.6em;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.bx--form-item,
.bx--dropdown__wrapper,
.bx--list-box__wrapper {
  margin-top: 0.8rem;
}

.field-error {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.33333;
  letter-spacing: 0.32px;
  overflow: hidden;
  margin: 0.25rem 0 0;
  color: #da1e28;
}

.btn-fluid {
  width: 100%;
  max-width: 100%;
}

.Toastify__toast--success {
  background: #00b189 !important;
}

.Toastify__toast--warning {
  background: #f8ac00 !important;
}

.bx--btn-set button {
  margin-right: 5px;
}

.required label.bx--label::before,
.required legend.bx--label::before {
  content: "*";
  color: #ef4800;
  font-size: 1.1rem;
}

.bx--label.section-header {
  font-size: 15px;
  font-weight: 900;
}

.section-subtitle {
  margin-bottom: 1.5rem;
}

.last-fieldset {
  margin-bottom: 0;
}

.bx--fieldset p {
  font-size: 14px;
  margin-bottom: 1rem;
}

.bx--fieldset ol {
  margin-left: 1rem;
  margin-bottom: 2rem;
  list-style: decimal;
}

.bx--fieldset ol li {
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.bx--label {
  font-weight: 600;
}

.bx--text-input:disabled {
  background-color: #d1ecf1;
  color: #0c5460 !important;
  cursor: pointer;
  -webkit-text-fill-color: #0c5460;
}

.bx--label--disabled,
.bx--form__helper-text--disabled {
  color: #161616;
}

.form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.view-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
}
/* Tab wizard */
.tab-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tab-navigation .bx--btn--ghost svg {
  margin: 0 5px;
}

.tab-navigation .bx--btn--ghost:hover,
.tab-navigation .bx--btn--ghost:active {
  color: #424242;
}
.tab-navigation .bx--btn--ghost:hover {
  background-color: #fff;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
table {
  border-collapse: collapse;
}

.table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}

.table tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.table th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  text-align: left;
  font-weight: 600;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.data-table-search-form {
  display: flex;
  width: 100%;
}

.data-table-search-form .bx--row {
  width: 100%;
}

.data-table-search-form .bx--form-item {
  margin-top: 0.4rem;
}

.data-table-search-form .bx--form-item .bx--select-input {
  height: 2rem;
}
.btn-main{
  background-color:"#f54428";
}